import { difference, forEach, isFunction, keys, values } from 'lodash'
import Link from 'next/link'
import { useRouter } from 'next/router'
import packageJson from 'package.json'
import queryStrings from 'query-string'
import { ParsedUrlQuery } from 'querystring'
import { Fragment, useMemo } from 'react'
import { component } from '~/utils/component'
import { __DEV__ } from '~/utils/__DEV__'

type Query = Record<string, string> & ParsedUrlQuery

/**
 * - AppLink 的進化版本
 * - renderProps 開發模式
 * - 與前代不同：多了 `renderProps.active` 可用
 *
 * @example
 *   //
 *   // 一般使用方法（你可能想用 AppLink 但你不關心它的 renderProps）
 *   return (
 *     <AppLink2 href='/heineken_template/demo/stateful'>
 *       <FuiButton.Display active={false}>孤獨的連結</FuiButton.Display>
 *     </AppLink2>
 *   )
 *
 * @example
 *   //
 *   // renderProps.active 使用方法
 *   return (
 *     <AppLink2 href='/heineken_template/demo/stateful'>
 *       {function Component(rProps) {
 *         return <FuiButton.Display active={rProps.active}>孤獨的連結</FuiButton.Display>
 *       }}
 *     </AppLink2>
 *   )
 */
export const AppLink2 = component<
  {
    href: string
  } & (
    | ReactProps
    | RenderProps<{
        /** 當前按鈕是否匹配當前網址 */
        active: boolean

        /**
         * 當前瀏覽器上的網址列的 `queryStrings`
         *
         * @example
         *   //
         *   // 假設頁面 `./pages/demo/user/[uid].page.tsx`
         *   // 並且網址 `http://localhost:2886/heineken_template/demo/user/GJEZsR3m70czJwk2SuLTMaqyoWH2`
         *   expect(query).toBe({
         *     uid: 'GJEZsR3m70czJwk2SuLTMaqyoWH2',
         *   })
         */
        query: Query
      }>
  )
>(props => {
  const router = useRouter()
  const routerAsQuery = router.query as Query

  const { href, hrefAs, isActive } = useMemo(() => {
    const _toUrl = `/${props.href}`
    const _href = `${_toUrl}`.replace(/^\/\//gi, '/')

    //
    const isOutsideLink = props.href.startsWith('http')

    const _hrefAs = isOutsideLink
      ? props.href
      : !__DEV__
      ? props.href.replace(/\/([\w\d-]+)\/?/i, '/').replace('//', '/')
      : ''

    //
    // 以下處理例如 `./pages/demo/user/[uid].page.tsx` 的情況
    let pathnameOfNextjs = router.pathname

    forEach(routerAsQuery, (value, key) => {
      pathnameOfNextjs = pathnameOfNextjs.replace(new RegExp(`(\\[${key}\\])`, 'gi'), value)
    })

    //
    // 以下處理 `?query=strings` 的相等，作為 active 的依據
    // 以「你給的 href as queryStrings」去比對「瀏覽器真實的 queryStrings」
    const propsHrefAsQuery = queryStrings.parse(
      // 只需要 問號（`?`）以後的字串去解析
      props.href.replace(/[\s\S]+\?/i, '?'),
    )

    const testQueryStrings = {
      'keys(routerAsQuery)': keys(routerAsQuery),
      'keys(propsHrefAsQuery)': keys(propsHrefAsQuery),
      'values(routerAsQuery)': values(routerAsQuery),
      'values(propsHrefAsQuery)': values(propsHrefAsQuery),
      get isKeysSame() {
        return (
          difference(
            testQueryStrings['keys(propsHrefAsQuery)'],
            testQueryStrings['keys(routerAsQuery)'],
          ).length === 0
        )
      },
      get isValuesSame() {
        return (
          difference(
            testQueryStrings['values(propsHrefAsQuery)'],
            testQueryStrings['values(routerAsQuery)'],
          ).length === 0
        )
      },
    }

    //
    const _isActive =
      props.href === pathnameOfNextjs ||
      (testQueryStrings.isKeysSame && testQueryStrings.isValuesSame)

    return { href: _href, hrefAs: _hrefAs, isActive: _isActive }
  }, [props.href, routerAsQuery, router.pathname])

  const children: React.ReactNode = isFunction(props.children) ? (
    props.children({
      active: isActive,
      query: routerAsQuery,
    })
  ) : (
    <Fragment>{props.children}</Fragment>
  )

  return (
    <Link
      key={href}
      href={href}
      as={hrefAs}
      className={props.className}
      prefetch={false}
    >
      {packageJson.dependencies.next.match(/12\.[\d]+\.[\d]+/) ? (
        <a
          className={props.className}
          href={href}
        >
          {children}
        </a>
      ) : (
        children
      )}
    </Link>
  )
})
